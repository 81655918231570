import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDasDeviceInfo } from "../../features/das-device-manager/dasDeviceManagerSlice";
import { getDeviceByModel } from "../../features/devices/deviceSlice";
import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import { formattedBalance } from "../../features/balance-and-pin/utils";

import Spinner from "../../components/Spinner";

import {
  AppStoreLinks,
  CurvelogoContainer,
  Footer,
  Wearable,
  MainContainer,
  Navbar,
  NextStepsContainer,
  StepContainer,
  StepNumber
} from "./styles";

import appStoreLogo from "../../assets/app-store.svg";
import gplayLogo from "../../assets/play-store.svg";
import curveLogo from "../../assets/curve_logo.png";
import euFlag from "../../assets/eu-flag.png";
import ukFlag from "../../assets/uk-flag.png";
import digiseqLogo from "../../assets/digiseq-logo.png";
import chevronUp from "../../assets/chevron-up.svg";
import chevronDown from "../../assets/chevron-down.svg";
import copyToClipboard from "../../assets/copy-icon.svg";
import payAnyWhereImage from "../../assets/pay-anywhere-image.png";
import mclearRing from "./img/mclear-ring.webp";

const McLear = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const [showPaymentAccount, setShowPaymentAccount] = useState(true);
  const [copied, setCopied] = useState(false);

  const dispatch = useDispatch();
  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  const { device: discoveryDevice, isLoading: isLoadingDevices } = useSelector(
    (state) => state.devices
  );

  const { dasDeviceInfo, gettingDasDeviceInfo } = useSelector(
    (state) => state.dasDevices
  );

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=much_better"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  useEffect(() => {
    if (!dasDeviceInfo && serialNumber) {
      dispatch(getDasDeviceInfo(serialNumber));
    }
  }, [dasDeviceInfo, dispatch, serialNumber]);

  useEffect(() => {
    if (
      dasDeviceInfo?.model?.model &&
      dasDeviceInfo?.model?.brand &&
      dasDeviceInfo?.model?.version
    ) {
      dispatch(
        getDeviceByModel({
          brand: dasDeviceInfo.model.brand,
          model: dasDeviceInfo.model.model,
          version: dasDeviceInfo.model.version
        })
      );
    }
  }, [
    dasDeviceInfo?.model?.brand,
    dasDeviceInfo?.model?.model,
    dasDeviceInfo?.model?.version,
    dispatch
  ]);

  const getDeviceImage = () => {
    if (
      discoveryDevice?.variants?.length > 0 &&
      dasDeviceInfo?.model?.variant?.color
    ) {
      const deviceVariant = discoveryDevice.variants.find(
        (variant) => variant.color === dasDeviceInfo.model.variant.color
      );

      if (deviceVariant?.image) {
        return deviceVariant.image;
      }
    } else if (discoveryDevice?.defaultImage) {
      return discoveryDevice.defaultImage;
    }

    return null;
  };

  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreLogo}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={gplayLogo}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  if (isLoadingDevices || gettingDasDeviceInfo || isLoading) return <Spinner />;

  return (
    <MainContainer>
      <Navbar>
        <div className="balance">
          <p>BALANCE</p>
          <p>{`${formattedBalance(
            balanceAndPin?.balanceDetails?.availableBalance,
            balanceAndPin?.balanceDetails?.currency
          )}`}</p>
        </div>
      </Navbar>

      <Wearable className="wearable">
        <img src={getDeviceImage() || mclearRing} alt="wearable" />
      </Wearable>

      <NextStepsContainer>
        <CurvelogoContainer>
          <h2>Payment Account</h2>
          <div
            className="expand-collapse"
            onClick={() => setShowPaymentAccount(!showPaymentAccount)}
          >
            <p className="title">Link your own card</p>
            <img
              src={showPaymentAccount ? chevronUp : chevronDown}
              alt="chevron up"
            />
          </div>
          {showPaymentAccount && (
            <>
              <img className="curve-logo" src={curveLogo} alt="curve logo" />
              <div className="flags">
                <img src={euFlag} alt="eu flag" />{" "}
                <img src={ukFlag} alt="uk flag" />
              </div>
            </>
          )}
        </CurvelogoContainer>
        {showPaymentAccount && (
          <>
            <StepContainer>
              <StepNumber>1</StepNumber>
              <h2>Create a Curve account</h2>
              <p>
                Register your card details with Curve to receive a free
                compatible digital bank card.
              </p>
              <p>Download the Curve App:</p>

              {appStoreLinks(
                "https://apps.apple.com/gb/app/curve-supercharge-your-money/id1049397112",
                "https://play.google.com/store/apps/details?id=com.imaginecurve.curve.prd"
              )}
            </StepContainer>
            <StepContainer>
              <StepNumber>2</StepNumber>
              <h2>Link Your Curve Card Using the Manage-Mii App</h2>

              <p>
                Follow the instructions on the Manage-Mii app and use your new
                Curve Card details when prompted.
              </p>
              {appStoreLinks(
                "https://apps.apple.com/app/manage-mii/id6446908503",
                "https://play.google.com/store/apps/details?id=com.digiseq.managemii"
              )}
            </StepContainer>
            <StepContainer>
              <StepNumber>3</StepNumber>
              <h2>Pay Anywhere!</h2>
              <p>
                Tap your wearable at any card terminal where Mastercard
                Contactless is enabled.
              </p>
              <img
                className="pay-anywhere"
                src={payAnyWhereImage}
                alt="pay anywhere"
              />
            </StepContainer>
          </>
        )}
      </NextStepsContainer>

      <Footer>
        <div className="copyToClipboard">
          <img
            onClick={() => {
              navigator.clipboard.writeText(serialNumber);
              setCopied(true);
            }}
            src={copyToClipboard}
            alt="copy to clipboard"
          />
          <p>ID: {serialNumber}</p>
        </div>
        {copied && (
          <p>
            <b>Copied to clipboard</b>
          </p>
        )}
        <p>Powered by</p>
        <img
          src={digiseqLogo}
          alt="digiseq-logo"
          onClick={() => window.open("https://www.digiseq.co.uk", "_blank")}
        />
      </Footer>
    </MainContainer>
  );
};

export default McLear;
