import { configureStore } from "@reduxjs/toolkit";

import deviceReducer from "./features/devices/deviceSlice";
import entityReducer from "./features/entities/entitySlice";
import authReducer from "./features/auth/authSlice";
import ascotReducer from "./features/ascot/ascotSlice";
import cagedSteelReducer from "./features/caged-steel/cagedSteelSlice";
import campaignsReducer from "./features/campaign-manager/campaignManagerSlice";
import eventsReducer from "./features/event-manager/eventManagerSlice";
import dasDevicesReducer from "./features/das-device-manager/dasDeviceManagerSlice";
import balanceAndPinReducer from "./features/balance-and-pin/balanceAndPinSlice";
import tap2StartReducer from "./features/tap2start/tap2StartSlice";
import businessCardReducer from "./features/business-card/businessCardSlice";
import hugoBossReducer from "./features/hugo-boss/hugoBossSlice";
import lostModeReducer from "./features/lost-mode/lostModeSlice";
import pay360SliceReducer from "./features/pay360/pay360Slice";
import scavengerHuntReducer from "./features/campaign-manager/scavengerHuntSlice";
import coachTrackerReducer from "./features/campaign-manager/coachTrackerSlice";
import worlds24Reducer from "./features/worlds24/worlds24Slice";
import giftingReducer from "./features/gifting/giftingSlice";
export const store = configureStore({
  reducer: {
    devices: deviceReducer,
    entities: entityReducer,
    auth: authReducer,
    ascot: ascotReducer,
    cagedSteel: cagedSteelReducer,
    campaigns: campaignsReducer,
    events: eventsReducer,
    dasDevices: dasDevicesReducer,
    balanceAndPin: balanceAndPinReducer,
    tap2Start: tap2StartReducer,
    businessCard: businessCardReducer,
    hugoBoss: hugoBossReducer,
    lostMode: lostModeReducer,
    pay360: pay360SliceReducer,
    scavengerHunt: scavengerHuntReducer,
    coachTracker: coachTrackerReducer,
    worlds24: worlds24Reducer,
    gifting: giftingReducer
  }
});
