import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import ekkoLogo from "./assets/ekko-logo.svg";
import digiseqLogo from "./assets/digiseq-logo.png";
import mastercardLogo from "./assets/mastercard-logo.png";
import plusIcon from "./assets/plus-icon.svg";
import minusIcon from "./assets/minus-icon.svg";

import {
  Container,
  Header,
  Title,
  CarbonFootprintCard,
  PoweredBy,
  Main,
  FAQSection,
  FAQItem
} from "./styles";

const Ekko = () => {
  const [isOpen, setIsOpen] = useState({ 1: false, 2: false });

  const [searchParams] = useSearchParams();
  const co2e = searchParams.get("co2e");

  console.log({ co2e });

  return (
    <Container>
      <Header>
        <Title>Planet action in every transaction</Title>
        <CarbonFootprintCard>
          <p>The carbon footprint of your last transaction is</p>
          <h2>{co2e || "6.8"} kgCO2e</h2>
          <a
            href="https://ekko.earth/"
            target="_blank"
            rel="noopener noreferrer"
          >
            What this means and how it's calculated
          </a>
        </CarbonFootprintCard>
        <PoweredBy>
          <p>Powered by</p>
          <div className="powered-by-logos">
            <img src={mastercardLogo} alt="Mastercard" />
            <img src={digiseqLogo} alt="DIGISEQ" />
            <img src={ekkoLogo} alt="ekko" />
          </div>
        </PoweredBy>
      </Header>

      <Main>
        <FAQSection>
          <FAQItem>
            <div className="faq-item-header">
              <p>What's CO2e?</p>
              <span
                onClick={() => setIsOpen((prev) => ({ ...prev, 1: !prev[1] }))}
              >
                <img src={isOpen[1] ? minusIcon : plusIcon} alt="plus" />
              </span>
            </div>
            {isOpen[1] && (
              <p>
                CO2e stands for “carbon dioxide equivalent”. While CO2 only
                accounts for carbon dioxide, CO2e accounts for carbon dioxide as
                well as all other greenhouse gases that contribute to climate
                change.
              </p>
            )}
          </FAQItem>
          <FAQItem>
            <div className="faq-item-header">
              <p>How is the carbon footprint of my transactions calculated?</p>
              <span
                onClick={() => setIsOpen((prev) => ({ ...prev, 2: !prev[2] }))}
              >
                <img src={isOpen[2] ? minusIcon : plusIcon} alt="plus" />
              </span>
            </div>
            {isOpen[2] && (
              <p>
                The carbon footprint of a transaction is calculated by
                evaluating the total CO2e emissions produced throughout the
                product's lifecycle, including raw material extraction,
                manufacturing, transportation, usage, and disposal.
              </p>
            )}
          </FAQItem>
        </FAQSection>
      </Main>
    </Container>
  );
};

export default Ekko;
